<template>
  <div class="shop-selection" :class="{ 'shop-selection--disabled': computedDisabled }">
    <SharedSelect
      v-if="Object.keys(candidateBranchModels).length === 0 && false === shouldDisplayLoading"
      :value="null"
      placeholder="查無資料"
      :hasBorder="false"
    />
    <div v-else-if="Object.keys(selectedBranchIds).length === 0 && shouldDisplayLoading" class="shop-selection__loading-wrapper mb-2">
      <b-spinner style="border-width: 2px" />
    </div>
    <template v-else>
      <!-- branch select -->
      <div v-for="(level) in Object.keys(candidateBranchModels)" :key="level" class="mb-2">
        <SharedSelect
          v-model="selectedBranchIds[level]"
          :options="formatBranchModelsToOptions(candidateBranchModels[level])"
          :placeholder="placeholder"
          :hasBorder="false"
          @input="handleAnyBranchSelected(selectedBranchIds[level], level)"
          :disabled="shouldDisplayLoading || disabled"
        />
      </div>
      <div v-if="shouldDisplayLoading" class="shop-selection__loading-wrapper mb-2">
        <b-spinner style="border-width: 2px" />
      </div>
    </template>
  </div>
</template>

<script>
// NOTE: branch -> 分區或分店, area -> 分區, shop -> 分店
import branchApi from "@/apis/liff/v2/branch"
import branchApiAdmin from "@/apis/branch"
import SharedSelect from "@/components/Page/Liff/Shared/Select"
import branchMixin from "@/mixins/Dashboard/branches"
import _ from "lodash"
import { mapState } from 'vuex'

export default {
  props: {
    value: { // NOTE: branch id of a shop
      type: String,
      required: false,
    },
    branchProvider: { // example: "waltily.branch", "waltily.branch:N004", "waltily.branch:N004:true"
      type: String,
      required: true,
    },
    showBranchCode: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "請選擇區域/分店",
    },
    apiSource: {
      type: String,
      default: 'liff',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [branchMixin],
  components: {
    SharedSelect,
  },
  data() {
    return {
      selectedBranchIds: {},
      selectedShopId: null,
      candidateBranchModels: {}, // NOTE: key -> branch level(does not always start with 0), value -> array of branch models
      isFetchingOptions: false,
      isPreparingSelectedOption: false,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    deepestCandidateLevel() {
      return Math.max(...Object.keys(this.candidateBranchModels))
    },
    shouldDisplayLoading() {
      return this.isFetchingOptions || this.isPreparingSelectedOption
    },
    computedDisabled() {
      return this.shouldDisplayLoading || this.disabled
    },
  },
  watch: {
    value(shopId) {
      this.selectedShopId = shopId

      if (!!shopId && this.selectedShopId === null) {
        this.loadBranchOptionsBySelectedShopId(shopId)
      }
    },
    shouldDisplayLoading(value) {
      if (value) {
        this.$emit('loading')
      } else {
        this.$emit('loaded')
      }
    },
  },
  mounted() {
    this.selectedShopId = this.value
  },
  methods: {
    async handleAnyBranchSelected(branchId, currentSelectionLevel) {
      const selectedBranch = this.candidateBranchModels[currentSelectionLevel].find(branch => branch.id == branchId)

      if (currentSelectionLevel !== this.deepestCandidateLevel) {
        this.cleanDeeperLevelOptions(currentSelectionLevel)
      }

      if (branchId === null) {
        this.$emit('input', null)
        this.$emit('selected', {
          type: 'placeholder',
          id: null,
          model: null,
        })
        this.$emit('selectedNull')
      } else if (this.isShop(selectedBranch)) {
        this.selectedShopId = branchId
        this.$emit('input', branchId)
        this.$emit('selected', {
          type: 'shop',
          id: branchId,
          model: selectedBranch,
        })
        this.$emit('setBranchModel', selectedBranch)
        this.$emit('selectedShop', branchId)
      } else if (this.isArea(selectedBranch)) {
        this.$emit('input', null)
        this.$emit('selected', {
          type: 'area',
          id: branchId,
          model: selectedBranch,
        })
        this.$emit('selectedArea', branchId)

        this.isFetchingOptions = true
        await this.fetchBranchesAndPrepareCandidates({ type: 'level', branch_id: branchId }, currentSelectionLevel + 1)
        this.isFetchingOptions = false

        if (this.candidateBranchModels[currentSelectionLevel].length > 0) {
          this.selectedBranchIds[currentSelectionLevel+1] = null
        }
      }
    },
    async fetchBranches(params) {
      try {
        if (this.apiSource === 'admin') {
          return _.get(await branchApiAdmin.getBranchOptions(this.organization, params), 'data.data', [])
        } else {
          return _.get(await branchApi.getBranches(params), 'data.data', [])
        }
      } catch (error) {
        console.error('[ShopSelection] Failed to fetch branches.')
        console.error(error)
      }
    },
    async fetchBranchesAndPrepareCandidates(params, levelForCandidates) {
      const childrenBranchModels = await this.fetchBranches(params)

      if (childrenBranchModels.length > 0) {
        this.$set(this.candidateBranchModels, levelForCandidates, childrenBranchModels)
      }
    },
    cleanDeeperLevelOptions(maximumPreservableLevel) {
      this.candidateBranchModels = Object.fromEntries(
        Object.entries(this.candidateBranchModels)
          .filter(([branchLevel, branchModel]) => branchLevel <= maximumPreservableLevel) // eslint-disable-line
      )
      this.selectedBranchIds = Object.fromEntries(
        Object.entries(this.selectedBranchIds)
          .filter(([branchLevel, selectedBranchId]) => branchLevel <= maximumPreservableLevel) // eslint-disable-line
      )
    },
    isArea(branchModel) {
      return _.get(branchModel, 'branch_type') === 'area'
    },
    isShop(branchModel) {
      return [null, 'shop'].includes(_.get(branchModel, 'branch_type'))
    },
    formatBranchModelsToOptions(branchModels) {
      if (!branchModels) {
        return []
      }
      return this.sortBranch(branchModels, branchModels[0]['org_id'], this.showBranchCode)
    },
    async loadBranchOptionsBySelectedShopId(shopId) {
      console.trace('[ShopSelection] loadBranchOptionsBySelectedShopId', shopId)
      this.isPreparingSelectedOption = true

      const allLevelCandidatesArePrepared = () => {
        return this.candidateBranchModels[0][0].parent_id === selectedLeafBranch.parent_id
      }

      // prepare top-level branch model candidates
      await this.$_fetchBranchesByProvider()

      // prepare all other level branch model candidates
      const allBranchModels = await this.fetchBranches({ type: 'all' })

      const groupedBranchModels = _.groupBy(allBranchModels, 'parent_id')
      const reversedCandidateBranchModels = {}
      const reversedSelectedBranchIds = {}
      let selectedShopLevel = 0

      let selectedLeafBranch = allBranchModels.find(branchModel => branchModel.id === shopId)
      while (false === allLevelCandidatesArePrepared() && selectedLeafBranch !== undefined) {
        reversedCandidateBranchModels[selectedShopLevel] = groupedBranchModels[selectedLeafBranch.parent_id]
        reversedSelectedBranchIds[selectedShopLevel] = selectedLeafBranch.id
        selectedLeafBranch = allBranchModels.find(branchModel => branchModel.id === selectedLeafBranch.parent_id)
        selectedShopLevel--
      }
      if (selectedLeafBranch) {
        this.selectedBranchIds[0] = selectedLeafBranch.id
      }

      Object.keys(reversedCandidateBranchModels).forEach(negativeLevel => {
        const restoredLevel = parseInt(negativeLevel) + Math.abs(selectedShopLevel)
        this.$set(this.selectedBranchIds, restoredLevel, reversedSelectedBranchIds[negativeLevel])
        this.$set(this.candidateBranchModels, restoredLevel, reversedCandidateBranchModels[negativeLevel])
      })

      this.isPreparingSelectedOption = false
    },
    async $_fetchBranchesByProvider() {
      if (!this.branchProvider) {
        throw new Error('[ShopSelection] Branch provider is not set.')
      }

      let providerSetting = this.branchProvider.split(':')
      switch (true) {
        case providerSetting[1] === 'all':
          // 全部分店
          return await this.fetchBranchesAndPrepareCandidates({ type: 'all' }, 0)
        case providerSetting[1] === 'level':
        case providerSetting[1] === undefined:
          // 階級獲取 只是從parent_id - null
          return await this.fetchBranchesAndPrepareCandidates({ type: 'level', branch_id: '' }, 0)
        default:
          // 階級獲取 從特定分店開始
          return await this.fetchBranchesAndPrepareCandidates({ type: 'specific', branch_code: providerSetting[1] }, 0)
      }
    },
    // below are APIs for parent components to call
    allSelectedBranches() {
      const forkCandidateBranchModels = Object.values(_.cloneDeep(this.candidateBranchModels)).reduce((acc, value, index) => {
          acc[index] = value;
          return acc;
        }, {});
      return Object.values(this.selectedBranchIds)
        .filter(Boolean)
        .map((branchId, index) => forkCandidateBranchModels[index].find(branchModel => branchModel.id === branchId))
    },
    async fetchBranchesOptions() {
      if (!this.branchProvider) {
        throw new Error('[ShopSelection] Branch provider is not set.')
      }

      this.isFetchingOptions = true

      if (this.value) {
        await this.loadBranchOptionsBySelectedShopId(this.value)
      } else {
        await this.$_fetchBranchesByProvider()
      }

      this.isFetchingOptions = false
    },
    async updateSelectedOption() {
      if (this.value) {
        this.isPreparingSelectedOption = true
        await this.loadBranchOptionsBySelectedShopId(this.value)
        this.isPreparingSelectedOption = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.shop-selection {
  select.s-form-control {
    width: 100%;
    border: 1px solid #e5e5ea !important;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
  }

  &--disabled select.s-form-control {
    border-color: #cdd6dc;
    background-color: #eee;
    color: #888;
  }

  .s-select__caret svg path {
    fill: #372745;
  }
}

.shop-selection {
  &__loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    background-color: white;
    padding: 4px;
  }

  &--disabled &__loading-wrapper {
    border-color: #cdd6dc;
    background-color: #eee;
    color: #888;
  }
}
</style>

<style lang="scss">
// style overwrites for Dashboard
.admin-panel .shop-selection {
  .s-select__caret {
    bottom: 0;
    transform: translateY(-50%);
  }

  select.s-form-control {
    height: 45px;
    border: 1px solid #cdd6dc !important;
    border-radius: 0;
    color: #372745;
    font-size: .875rem;
    line-height: 14px;
  }

  &__loading-wrapper {
    height: 45px;
    border-radius: 0;
  }
}
</style>
